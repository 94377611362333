<template>
  <button
    v-if="userStore.isLoggedIn"
    :class="{
      selected: isSelected,
      'btn btn--secondary': design === 'withText'
    }"
    @click="toggleFavoriteBrand"
  >
    <span
      v-if="design === 'withText'"
    >
      {{ isSelected ? $t('favorite.button.remove.brand') : $t('favorite.button.add.brand') }}
    </span>
    <img
      :src="isSelected ? '/icons/heart-filled.svg' : '/icons/heart.svg'"
      class="inline-block"
      alt=""
      :class="{
        'favoriteAnimateIn': isSelected,
        'w-16 ml-8': design === 'withText',
        'w-20': design === 'minimal',
      }"
    >
  </button>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';
const userStore = useUserStore();

const props = defineProps<{
  brandId: string;
  design: 'withText' | 'minimal';
}>();

const isSelected = computed(()=> {
  return userStore.userProfile.favoriteBrands.includes(props.brandId);
});

const toggleFavoriteBrand = () => {
  if (!isSelected.value) {
    userStore.addFavoriteBrand(props.brandId);
  } else {
    userStore.removeFavoriteBrand(props.brandId);
  }
};

</script>

<style scoped lang="postcss">
</style>
