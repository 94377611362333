<template>
  <component
    :is="$sbLinkCheck(filter.link) ? NuxtLink : 'div'"
    :to="$sbLinkFix(filter.link)"
    :target="$sbLinkTarget(filter.link)"
    class="tabImageHeight order-3 flex flex-col justify-end p-12 desk:p-24"
    :class="{
      'text-lightest': filter.invertText
    }"
    :style="tabImage"
  >
    <div class="type-headline-lg desk:type-headline-2xl">{{ filter.name }}</div>
    <div
      v-if="filter.linkText"
      class="type-headline-xs desk:type-headline-sm mt-8"
    >
      {{ filter.linkText }}
      <img
        :src=" filter.invertText ? '/icons/arrow-right-inv.svg' : '/icons/arrow-right.svg'"
        class="inline-block w-16 desk:w-20 ml-12"
      >
    </div>
  </component>
</template>

<script setup lang="ts">
import { FilterTypeAlgolia } from '~/constants/types/storyblok';
import { storyblokScaleImage } from '~/util/imageUtil';
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();
const NuxtLink = resolveComponent('NuxtLink');

const props = defineProps<{
  filter: FilterTypeAlgolia,
  desktopDesign: 'slider' | 'default' | 'large' | 'featured' | 'slider-large' | 'slider-featured',
  mobileDesign: 'grid' | 'slider' | 'featured',
}>();

const tabImage = computed(()=> {

  let size = props.mobileDesign === 'featured' ? 375 :  159; // mobile
  if (!uiStore.isMobile) {
    switch (props.desktopDesign) {
      case 'large':
      case 'slider-large':
        size = 318;
        break;
      case 'featured':
      case 'slider-featured':
        size = 648;
        break;
      default:
        size = 252;
    }
  }

  const url = storyblokScaleImage(props.filter.image.filename, size);
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
  };
});

</script>

<style scoped lang="postcss">
</style>
