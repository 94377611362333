<template>
  <div
    class="AutoBreadcrumbs type-headline-xxs desk:type-headline-xs text-dark mb-20 text-center"
    :class="desktopWidth('full')"
  >
    <div
      v-for="(link, index) in tree"
      :key="link.slug + index"
      class="inline-block"
    >
      <nuxt-link
        :to="link.slug"
        class="underline decoration-light whitespace-nowrap hover:text-darkest hover:decoration-darkest transition-all"
      >
        {{ link.name }}
      </nuxt-link>
      <span
        v-if="index !== tree.length - 1"
      >&nbsp;/&nbsp;</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import useDesktopWidth from '~/composeables/useDesktopWidth';
const globalContent = useGlobalContentStore();

const props = defineProps<{
  excludeSelf?: boolean,
  excludeTopLevel?: boolean;
}>();

const { desktopWidth } = useDesktopWidth(props);
await useAsyncData('load-breadcrumbs',
  async() => {
    return await globalContent.loadSitemap();
  }
);

type TreeItem = {
  id: number;
  name: string;
  slug: string;
  parentId: number;
}

const tree = computed<TreeItem[]>(()=> {
  let output = [] as TreeItem[];
  if (globalContent.currentStory?.id) {
    let parentId = globalContent.currentStory.id;

    while (parentId !== null && parentId !== 0) {
      const item = globalContent.getSitemapItemById(parentId);
      const usedIds = output.map((m) => m.id);
      if (item) {
        if (!item.is_folder) {
          if (!usedIds.includes(item.id)) {
            output.push({
              id: item.id,
              name: item.name,
              slug: '/' + item.full_slug,
              parentId: item.parent_id,
            });
          }
        } else {
          // It's a folder, check if we have root page for the folder
          const item2 = globalContent.getSitemapIndexPageFromFolder(item.id);
          if (item2 && !usedIds.includes(item2.id)) {
            output.push({
              id: item2.id,
              name: item2.name,
              slug: '/' + item2.full_slug,
              parentId: item2.parent_id,
            });
          }
        }
        parentId = item.parent_id;
      } else {
        parentId = null;
      }
    }
    if (props.excludeTopLevel && output.length) {
      output.pop();
    }
    output.reverse();
    if (props.excludeSelf && output.length) {
      output.pop();
    }
  }

  return output;
});

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: tree.value.map((breadcrumb, i) => {
    return {
      '@type': 'ListItem',
      'position': i,
      'item': {
        '@id': breadcrumb.slug,
        'name': breadcrumb.name,
      },
    };
  }),
} as any;

useJsonld(jsonLd);
</script>

<style scoped lang="postcss">
</style>
