/**
 * Fixes padding for scroll
 * This is ment to be used with a div .scrollableDiv for nicer scrollbars
 *
 * Example usage:
 * import { useHasScroll } from '~/composeables/useScrollFix';
 *
 * <div ref="scrollElement" class="max-h-320 overflow-auto scrollableDiv">
 * ...
 * const scrollElement = ref(null);
 * useHasScroll(scrollElement);
 */

import { onMounted, onUnmounted, Ref } from 'vue';

export const useHasScroll = (elementRef: Ref<HTMLElement | null>, className: string = 'hasScroll') => {
  let resizeObserver: ResizeObserver | null = null;

  const updateScrollClass = (el: HTMLElement) => {
    const hasScroll = el.scrollHeight > el.clientHeight;
    if (hasScroll) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  };

  onMounted(() => {
    const currentElement = elementRef.value;
    if (currentElement) {
      resizeObserver = new ResizeObserver((entries) => {
        entries.forEach(entry => {
          requestAnimationFrame(() => {
            updateScrollClass(entry.target as HTMLElement);
          });
        });
      });
      resizeObserver.observe(currentElement);
    }

    window.addEventListener('error', (e) => { console.log('error', e.message); });
  });

  onUnmounted(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
      resizeObserver = null;
    }
  });
};
