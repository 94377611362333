<template>
  <div>
    <div v-if="tabsHeadline" class="leading-single type-headline-sm mb-28">{{ tabsHeadline }}</div>
    <button
      v-for="(filter, index) in adminFilters"
      :key="filter._uid"
      :class="getClass(index)"
      @click="$emit('select', index)"
    >
      {{ filter.name }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { FilterTypeAlgolia } from '~/constants/types/storyblok';

const props = defineProps<{
  adminFilters: FilterTypeAlgolia[],
  selectedAdminFilter: number,
  design: 'tabs' | 'inline',
  tabsHeadline?: string,
}>();

defineEmits<{
  (e: 'select', input: number): void;
}>();

const getClass = (index: number) => {
  let classes = props.design === 'tabs' ?
    'relative top-1 z-above px-12 desk:px-16 pb-4 desk:pb-8 type-headline-xs desk:type-headline-sm whitespace-nowrap'
    : 'block type-headline-2xl leading-single mb-24';

  if (props.design === 'tabs') {
    if (props.selectedAdminFilter === index) {
      classes += ' border-b border-darkest';
    } else {
      classes += ' text-dark';
    }
  } else {
    if (props.selectedAdminFilter === index) {
      classes += ' ';
    } else {
      classes += ' text-dark';
    }
  }

  return classes;
};

</script>

<style scoped lang="postcss">
</style>
